require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// require("../src/wizard")
require("../src/custom")
require("../src/owl.carousel.min")
require('jquery')
import "cocoon";

// require("../src/jquery.min")
// require("src/now-ui-dashboard.min")
// require("src/perfect-scrollbar.jquery.min")
//= require dataTables/jquery.dataTables

require.context('../images', true)

require("bootstrap")
import "../stylesheets/application.css.scss";
document.addEventListener("turbolinks:load", function() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
        $('[data-toggle="popover"]').popover()
    })
})

